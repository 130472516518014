@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_Black.3f3ac54f.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_ExtraBold.c93e02eb.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_Bold.8ce52807.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_DemiBold.1ab15718.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_Medium.9c9fff90.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_Regular.5fd6e13a.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: TT Wellingtons;
  src: url("TT_Wellingtons_Light.0f7d863b.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Zoopla Sans;
  src: url("ZooplaSans-Regular.d23a8239.woff") format("woff"), url("ZooplaSans-Regular.d23a8239.woff") format("woff2");
  font-weight: 400 normal;
  font-display: swap;
}

@font-face {
  font-family: Zoopla Sans;
  src: url("ZooplaSans-Light.b75b5568.woff") format("woff"), url("ZooplaSans-Light.b75b5568.woff") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Zoopla Sans;
  src: url("ZooplaSans-SemiBold.a0d130fe.woff") format("woff"), url("ZooplaSans-SemiBold.a0d130fe.woff") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Zoopla Sans;
  src: url("ZooplaSans-Bold.9ece5b14.woff") format("woff"), url("ZooplaSans-Bold.9ece5b14.woff") format("woff2");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Bold.23054c2a.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-SemiBold.c0f07c7f.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.d5d72893.ttf") format("truetype");
  font-display: swap;
}

.buttonBack___1mlaL, .buttonFirst___2rhFr, .buttonLast___2yuh0, .buttonNext___2mOCa, .buttonNext___3Lm3s, .dot___3c3SI {
  cursor: pointer;
}

.image___xtQGH {
  width: 100%;
  height: 100%;
  display: block;
}

.spinner___27VUp {
  width: 30px;
  height: 30px;
  border: 4px solid #a9a9a9;
  border-top-color: #000;
  border-radius: 30px;
  animation-name: spin___S3UuE;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

@keyframes spin___S3UuE {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.container___2O72F {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.overlay___IV4qY {
  opacity: 0;
  cursor: zoom-in;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hover___MYy31, .loading___1pvNI, .zoom___3kqYk {
  opacity: 1;
}

.imageLoadingSpinnerContainer___3UIPD {
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slide___3-Nqo {
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none;
  display: block;
  position: relative;
}

.slide___3-Nqo:focus {
  outline: none !important;
}

.slideHorizontal___1NzNV {
  float: left;
}

[dir="rtl"] .slideHorizontal___1NzNV {
  direction: rtl;
  transform: scaleX(-1);
}

.slideInner___2mfX9 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.focusRing___1airF {
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .focusRing___1airF {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.horizontalSlider___281Ls {
  touch-action: pan-y pinch-zoom;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .horizontalSlider___281Ls {
  direction: ltr;
  transform: scaleX(-1);
}

.horizontalSliderTray___1L-0W {
  width: 100%;
  overflow: hidden;
}

.verticalSlider___34ZFD {
  position: relative;
  overflow: hidden;
}

.verticalSliderTray___267D8 {
  overflow: hidden;
}

.verticalTray___12Key {
  float: left;
}

.verticalSlideTrayWrap___2nO7o {
  overflow: hidden;
}

.sliderTray___-vHFQ {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.sliderAnimation___300FY {
  will-change: transform;
  transition: transform .5s cubic-bezier(.645, .045, .355, 1);
}

.masterSpinnerContainer___1Z6hB {
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.c73e5358.css.map */
