/* Main Mojo fonts */

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_Black.otf") format('opentype');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_ExtraBold.otf") format('opentype');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_Bold.otf") format('opentype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_DemiBold.otf") format('opentype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_Medium.otf") format('opentype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_Regular.otf") format('opentype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: TT Wellingtons;
    src: url("./assets/fonts/TT_Wellingtons_Light.otf") format('opentype');
    font-weight: 300;
    font-display: swap;
}

/* Special Zoopla fonts */

@font-face {
    font-family: Zoopla Sans;
    src: url("./assets/fonts/ZooplaSans-Regular.woff") format('woff'),
         url("./assets/fonts/ZooplaSans-Regular.woff") format('woff2');
    font-weight: 400 normal;
    font-display: swap;
}
@font-face {
    font-family: Zoopla Sans;
    src: url("./assets/fonts/ZooplaSans-Light.woff") format('woff'),
         url("./assets/fonts/ZooplaSans-Light.woff") format('woff2');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: Zoopla Sans;
    src: url("./assets/fonts/ZooplaSans-SemiBold.woff") format('woff'),
         url("./assets/fonts/ZooplaSans-SemiBold.woff") format('woff2');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: Zoopla Sans;
    src: url("./assets/fonts/ZooplaSans-Bold.woff") format('woff'),
         url("./assets/fonts/ZooplaSans-Bold.woff") format('woff2');
    font-weight: 800;
    font-display: swap;
}


/* Special Confused fonts */
/* see https://zeroheight.com/784b13be8/p/64e8ad-typography/b/46fc7b */
@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-Bold.ttf") format('truetype');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-SemiBold.ttf") format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-Regular.ttf") format('truetype');
    /* font-weight: 100 normal; */
    font-display: swap;
}
